/* App.css */

body {
  background-color: #1e1e1e;
  color: #fff;
}

.App {
  text-align: center;
  padding: 20px;
}

.blotter {
  display: flex;
  flex-direction: column;
  border: 1px solid #4a4a4a;
  background-color: #333;
}

.row {
  display: flex;
  border-bottom: 1px solid #4a4a4a;
  transition: background-color 1s ease;
}

.row.updated {
  background-color: #ff4d4f;
  opacity: 0.8;
}

.row:hover {
  background-color: #050505e8;
}

.header {
  font-weight: bold;
  background-color: #555;
}

.cell {
  padding: 8px;
  flex: 1;
  border-right: 1px solid #4a4a4a;
}

.cell:last-child {
  border-right: none;
}

.App-header {
  background-color: #222;
  padding: 20px;
}

h1 {
  color: #fff;
}

p {
  color: #ccc;
}

.error {
  color: #ff4d4f;
}
